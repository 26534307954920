.video {
  padding-bottom: 70px;

  &__content {
    width: 834px;
    height: 375px;
    margin: 0 auto;
    background: #D9D9D9;
    @media  screen and (max-width: 1023px) {
      width: 350px;
      height: 151px;
      margin: 0 auto; 
    
    }  
  }
}