#back2Top {
    overflow: hidden;
    z-index: 999;
    display: none;
    cursor: pointer;
    position: fixed;
    bottom: 0px;
    right: 0;
    text-align: center;
    margin: 30px;
    @media  screen and (max-width: 1023px) {
        margin: 10px; 
    }
}