
.booking {
  &__content {
    position: relative;
    height: 470px;
    width: 100%;
    margin: 0;
    background-image: url("../images/booking.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    @media  screen and (max-width: 1023px) {
      height: 300px;
      background-size: cover;
    }
  }

  &__text {
    position: absolute;
    left: 9%;
    top: 29%;
  }

  &__title {
    margin-bottom: 11px;
    @media  screen and (max-width: 1023px) {
      margin-bottom: 4px;
      font-size: 32px;
      line-height: 40px;
    }
  }

  &__subtitle {
    max-width: 477px;
    @media  screen and (max-width: 1023px) {
      font-size: 14px;
      line-height: 17px;
    }
  }
}