/* open-sans-regular - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans/open-sans-v29-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/open-sans/open-sans-v29-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/open-sans/open-sans-v29-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/open-sans/open-sans-v29-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/open-sans/open-sans-v29-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/open-sans/open-sans-v29-latin_cyrillic-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-500 - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/open-sans/open-sans-v29-latin_cyrillic-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/open-sans/open-sans-v29-latin_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/open-sans/open-sans-v29-latin_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/open-sans/open-sans-v29-latin_cyrillic-500.woff') format('woff'), /* Modern Browsers */
  url('../fonts/open-sans/open-sans-v29-latin_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/open-sans/open-sans-v29-latin_cyrillic-500.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/open-sans/open-sans-v29-latin_cyrillic-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/open-sans/open-sans-v29-latin_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/open-sans/open-sans-v29-latin_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/open-sans/open-sans-v29-latin_cyrillic-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/open-sans/open-sans-v29-latin_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/open-sans/open-sans-v29-latin_cyrillic-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}