.baikal-header {
  background: rgba(0, 0, 0, 0.1) url("../images/baikal/header.jpg") no-repeat center center;
  background-size: cover;
  background-blend-mode: darken;

  .wrapper {
    height: 454px;
    background-size: cover;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__title {
    font-weight: 800;
    font-size: 50px;
    line-height: 62px;
    margin-bottom: 14px;
  }

  &__subtitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #FFFFFF;
  }
}