

  header {
	position: relative;
}
.menu-triger {
	display:none;
	@media  screen and (max-width: 1023px) {
		background-image: url(../images/menu/menu_color.svg);
 		background-repeat: no-repeat;
  		background-size: cover; }
		display: block;
		width: 25px;
		height: 17px; 
	}
		

.menu-popup {
	background-color: var(--blue);
	display: none;
	position: absolute;
	right: -110%;
	top: 0;
	max-width: 1023px;
	box-sizing: border-box;
	padding: 35px;
	margin: 0 auto;
	z-index: 9999;	
	width: 100%;
    position: fixed;
    overflow: hidden;	
}

.menu_list {
	display: flex;
}

.menu-popup ul {
	margin: 0;
	padding: 0;
}
.menu-popup li {
	list-style: none;
	margin: 10px 0;
	padding: 0;
}
.menu-popup li a {
	font-size: 18px;
	color: rgb(255, 255, 255);
	line-height: 1.4;	
	text-decoration: none;
	padding-right: 30px;
	&:hover {
		font-size: 19px;
	}
}
.body_pointer * {
	cursor: pointer;
}  