.footer {
    align-items: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
  }

.section-footer {
      padding-top: 116px;
      padding-bottom: 316px;
      padding-left: 44px;
    &.contacts {
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 38px;
        color: #fff;
        padding-bottom: 31.5px;
    }
    @media  screen and (max-width: 1023px) {
      padding-top: 38px;
    }
  }  
