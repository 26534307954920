.section-advantages {
  &__title {
    align-items: center;
    text-align: center;
    font-weight: 800;
    font-size: 30px;
    line-height: 38px;
    text-transform: capitalize;
    color: #fff;
    padding-left: 393px;
    padding-right: 392px;
    @media  screen and (max-width: 1023px) {
      padding-left: 0px;
      padding-right: 0px;

    }

  }

  .wrapper{
    padding-top: 71px;
    padding-bottom: 71px;
  }
}

.advantages {
  display: flex;
  align-items: flex-start;
  list-style: none;
  margin: auto;
  padding: 0;
}

.advantages__item {
  text-align: center;
  margin-right: 50px;
  @media  screen and (max-width: 1023px) {
    margin-right: 10px;
  }

  &:last-child {
    margin-right: 0;
  }

  &-icon {
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media  screen and (max-width: 1023px) {
      width: 60px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;

      

    }

  }

  &-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #fff;
    @media  screen and (max-width: 1023px) {
      font-size: 11px;
    }
  
  }
}