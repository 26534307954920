.baikal-top {
    .two-column__left {
      margin-right: 30px;
      @media  screen and (max-width: 1023px) {
        margin-right: 0px;
      }
    }
}

.baikal_info_bottom_image {
  @media  screen and (max-width: 1023px) {
    max-width: 50%;
  }
}