.info-section {
  .wrapper {
    padding: 120px 35px;
    @media  screen and (max-width: 1023px) {
      padding: 60px 21px;
    }
  }
}

.info-section__image {
  position: relative;
  z-index: 2;
  display: flex;

  &.is-with-frame {
    @media  screen and (max-width: 1023px) {
      padding-right: 15px;
      padding-left: 15px;
    }
    &:before, &:after {
      content: "";
      display: block;
      background: var(--blue);
      position: absolute;
      z-index: -1;
      @media  screen and (max-width: 1023px) {
        margin-right: 21px;
        margin-left: 21px;
      }
    }

    &:before {
      width: 76%;
      height: 58%;
      left: -22px;
      top: -22px;
    }

    &:after {
      width: 31%;
      height: 51%;
      right: -22px;
      bottom: -22px;
    }
  }
}

.baikal-info-section__image {
  position: relative;
  z-index: 2;

  &.is-with-frame {
    &:before, &:after {
      content: "";
      display: block;
      background: var(--blue);
      position: absolute;
      z-index: -1;
    }

    &:before {
      width: 76%;
      height: 58%;
      left: -22px;
      top: -22px;
    }

    &:after {
      width: 31%;
      height: 51%;
      right: -22px;
      bottom: -22px;
    }
  }
}

.padding-bottom {
  padding-bottom: 17px;
}

.padding-right {
  padding-right: 21px;
  @media  screen and (max-width: 1023px) {
    padding-right: 18px;
  }
}