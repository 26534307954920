:root {
  --black: #0F0F0F;
  --blue: #5A7BEB;
}

* {
  box-sizing: border-box;
}

html, body {
  font-family: 'Open Sans', sans-serif;
  color: #000;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  overflow-x:hidden;  /* Скрываем scrollbars */
}


.wrapper {
  max-width: 1000px;
  margin: 0 auto;

  &.is-padding-35 {
    padding-left: 35px;
    padding-right: 35px;
    @media  screen and (max-width: 1023px) {
      padding-left: 21px;
      padding-right: 21px;
    }
  }
}

.logo {
  height: 63px;
}

.banner__title {
  font-weight: 800;
  font-size: 50px;
  line-height: 62px;
  color: #fff;
  margin: 0;
}

.banner__subtitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #fff;
}

.text-title {
  color: var(--blue);
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 38px;
  text-transform: capitalize;
  margin: 0 0 10px;
}

.text-subtitle {
  color: var(--blue);
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
}

.text-content {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  &.is-with-top-margin {
    margin-top: 42px;
    @media  screen and (max-width: 1023px) {
      margin-top: 30px;
    }
  }

  p {
    margin: 0 0 21px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.flex {
  display: flex;
}

section.is-blue {
  background: var(--blue);
}

.mw-100 {
  max-width: 100%
}

.pb-60 {
  @media  screen and (max-width: 1023px) {
    padding-bottom: 60px !important
  }
  
}


@import "./fonts";
@import "./scroll.scss";
@import "./mobile_menu.scss";

@import "./header";
@import "./navigation";
@import "./links";
@import "./two-column";
@import "./advantages";
@import "./info-section";
@import "./gallery";
@import "./video";

@import "./booking";
@import "./olymp/top";
@import "./olymp/bottom";

@import "./baikal/header";
@import "./baikal/top";
@import "./baikal/bottom";

@import "./footer";