.header {
  .wrapper {
    display: flex;
    align-content: center;
    padding-top: 48px;
    padding-bottom: 48px;
    width: 100%;
  }

  &__left, &__right {
    width: 210px;
  }

  &__left {
    text-align: left;
  }

  &__right {
    display: flex;
    text-align: right;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
  }

  nav {
    align-items: center;
    flex: 1;
    padding: 0 1em;
    justify-content: center;
    display: none;
    @media  screen and (min-width: 1024px) {
      display: flex; 
    }
  }
  
}
