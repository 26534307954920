.two-column {
  display: flex;
  @media  screen and (max-width: 1023px) {
    display: block; 
  }

  &__right {
    flex: 1;
    align-items: center;
  }
  &__left {
    flex: 1;
    align-items: center;
  }

}