.navigation {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;

  &__item {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }

    a {
      font-size: 14px;
      line-height: 19px;
      font-style: normal;
      font-weight: 600;
    }
  }
}