.gallery {
  padding-bottom: 60px;
  width: 100%;

  .gallery__title {
    font-weight: 800;
    font-size: 30px;
    line-height: 38px;
    color: var(--blue);
    margin: 0 0 122px;
    text-align: center;
    @media  screen and (max-width: 1023px) {
      margin: 0 0 60px;
    }
  }
}

.gallery__items {
  display: flex;
  width: 100%; 
}

.gallery__item {
  flex: 0 2 40%;
  margin-right: 33px;
  justify-content: center;
  @media  screen and (max-width: 1023px) {
    margin-right: 18px;
  }


  &:last-child {
    margin-right: 0;
  }
}